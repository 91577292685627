<template>
  <div v-if="current === 0" :class="$style['warn_item_height']">
    <div v-if="climbList.warn.length > 0" :class="$style['warn_wrap']">
      <div
        :class="$style['warn_item']"
        v-for="(item, index) in climbList.warn"
        :key="index"
      >
        <span>{{ index + 1 }}、{{ item.typeStr }}</span>
        <span :class="$style.count" @click.stop="handleCount(item)">
          {{ item.countNum }} {{ $t('screen.times') }}
        </span>
      </div>
    </div>
    <no-data-chart v-else />
  </div>
  <div v-else :class="$style['warn_item_height']">
    <div v-if="climbList.error.length > 0" :class="$style['warn_wrap']">
      <div
        :class="$style['warn_item']"
        v-for="(item, index) in climbList.error"
        :key="index"
      >
        <span>{{ index + 1 }}、{{ item.typeStr }}</span>
        <span :class="$style.count" @click.stop="handleCount(item)">
          {{ item.countNum }} {{ $t('screen.times') }}
        </span>
      </div>
    </div>
    <no-data-chart v-else />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import WarnErrorDetail from '@/views/iot/screen/components/warn-error';

@Component
export default class WarnError extends Vue {
  @Prop({ type: Object, default: null }) climbList;
  @Prop({ type: Number, default: 0 }) current;

  handleCount(item) {
    createModal(() => <WarnErrorDetail dataList={item.details} />, {
      title: '统计详情',
      width: 900,
      className: this.$style['x-warn-error-detail'],
    });
  }
}
</script>
<style lang="less" module>
.warn_item_height {
  height: 100%;
}
.warn_wrap {
  padding: 0.1rem 0.2rem;
}
.warn_item {
  display: flex;
  justify-content: space-between;
  line-height: 0.3rem;
  color: #ffffff;
  padding: 0 0.2rem;
  font-size: 0.14rem;
  &:hover {
    background: rgba(5, 211, 255, 0.1);
  }
}
.count {
  cursor: pointer;
}
.x-warn-error-detail {
  :global {
    .ant-modal-content {
      background-color: #031d3f;
      .ant-modal-header {
        background-color: #1d3453;
        .ant-modal-title {
          color: #05d2fe;
        }
      }
      .ant-modal-close {
        color: #05d2fe;
      }
      .ant-table-thead > tr > th {
        color: #05d2fe;
      }
      .ant-table-tbody > tr > td {
        color: #05d2fe;
        background-color: #1d3453;
        border-bottom-color: #1d3453;
      }
      .ant-pagination-item,
      .ant-pagination-next .ant-pagination-item-link,
      .ant-pagination-prev .ant-pagination-item-link {
        border-color: #05d2fe;
      }
    }
  }
}
</style>
