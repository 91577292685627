<template>
  <info-tab :title="title">
    <div slot="content_0" :class="$style.container">
      <no-data-chart v-if="isNoData" />
      <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
    </div>
  </info-tab>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import InfoTab from '../../info-tab';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
const dataZoom = [
  {
    type: 'slider',
    show: true,
    start: 0,
    end: 100,
    xAxisIndex: [0],
    // handleSize: 20,//滑动条的 左右2个滑动条的大小
    height: 8, // 组件高度
    left: 40, // 左边的距离
    right: 10, // 右边的距离
    bottom: 0, // 右边的距离
    handleColor: 'transparen',
    fillerColor: '#075D82',
    borderColor: '#05314E',
    backgroundColor: '#05314E',
    handleStyle: {
      borderColor: '#cacaca',
      borderWidth: '0',
      shadowBlur: 0,
      backgroundColor: '#f8f8f8',
    },
    showDataShadow: false, // 是否显示数据阴影 默认auto
    showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
    handleIcon:
      'M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
    filterMode: 'filter',
  },
  // 下面这个属性是里面拖到
  {
    type: 'inside',
    show: true,
    xAxisIndex: [0],
    start: 1,
    end: 10,
    zoomOnMouseWheel: false,
  },
];
const lineOptions = (data, nowSize, $t) => {
  const category = data.map(e => e.buildingName);
  const values = data.map(e => e.climbHeight);
  let max = Math.max(...values);
  max += 10 - (max % 10);
  if (data.length > 8) {
    dataZoom[0].show = true;
    dataZoom[0]['end'] = 100 * (8 / category.length);
  } else {
    dataZoom[0].show = false;
  }
  return {
    dataZoom,
    tooltip: {
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      axisPointer: {
        type: 'line',
        z: 1,
        lineStyle: {
          color: '#fff',
          opacity: 0.2,
        },
      },
      formatter: '{b}: {c}' + $t('screen.meter'),
      extraCssText: 'border-radius: 0; padding: 10px;',
    },
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: category,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
          fontSize: 10,
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
      },
    },
    yAxis: {
      min: 0,
      max,
      offset: 6,
      splitLine: {
        show: false,
      },
      type: 'value',
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
          fontSize: 10,
        },
      },

      axisLabel: {
        formatter(val) {
          return `{a|${val + $t('screen.meter')}}`;
        },
        margin: 0 - 2,
        fontSize: nowSize(12),
        width: 20,
        rich: {
          a: {
            align: 'left',
          },
        },
      },
    },
    series: [
      {
        name: 'test',
        type: 'bar',
        stack: '总量',
        smooth: true,
        barWidth: nowSize(18),
        barGap: '30%',
        data: values,
        label: {
          normal: {
            color: '#fff',
            textBorderColor: 'transparent',
            show: true,
            position: 'top',
            formatter: function(e) {
              return (
                data[e.dataIndex] &&
                data[e.dataIndex]['climbNumber'] &&
                data[e.dataIndex]['climbNumber'] + $t('screen.floor')
              );
            },
          },
          fontSize: nowSize(14),
        },
        itemStyle: {
          normal: {
            color: '#05D3FF',
            lineStyle: {
              width: 3,
              type: 'solid',
              color: '#4fd6d2',
            },
            label: {
              textStyle: {
                fontSize: nowSize(12),
              },
            },
          },
        },
      },
    ],
  };
};
@Component({
  mixins: [resize],
  components: {
    InfoTab,
  },
})
export default class ClimbingHeight extends Vue {
  get title() {
    return [this.$t('screen.buildingHeight')];
  }
  @Prop({ type: String, default: 'HighBuilding' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  chart = null;
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$size, this.$t);
    this.chart.setOption(options);
  }
  resizeChart() {
    this.chart && this.chart.resize();
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
