<template>
  <div :class="$style['info-wrap']">
    <div
      @click="openMessage"
      :class="[
        $style['message'],
        +unReadMessageCount && noVisitorMode ? $style.un_read : '',
      ]"
      class="TC tc-icon-bell1"
    >
      <!-- <span v-if="unReadMessageCount > 0" :class="$style['num']">{{
        unReadMessageCount
      }}</span> -->
    </div>
    <div
      v-if="hasLogin"
      @click="toThreePage"
      :class="[$style.iconBox, $style.mouseCursor]"
    >
      <x-icon :class="[$style['icon']]" type="tc-icon-webpack" />
      <span :class="$style['text']">3D</span>
    </div>
    <div
      v-if="attentionData.isAuthority"
      :class="[$style.iconBox, $style.mouseCursor]"
      @click="checkConcerned"
    >
      <!--      <span :class="[$style['icon']]" class="iconfont"></span>-->
      <a-icon
        type="heart"
        :theme="attentionData.isAttention ? 'filled' : 'outlined'"
        :class="[$style['icon']]"
      />
      <span :class="$style['text']">{{ $t('screen.like') }}</span>
    </div>
    <div
      v-if="hasLogin"
      :class="[$style.iconBox, $style.mouseCursor]"
      @click="checkRecommend"
    >
      <!--      <span :class="[$style['icon']]" class="iconfont"></span>-->
      <a-icon
        type="like"
        :theme="recommendData.isRecommend ? 'filled' : 'outlined'"
        :class="[$style['icon']]"
      />
      <span :class="$style['text']">{{ $t('screen.collection') }}</span>
    </div>
    <div :class="$style.iconBox">
      <span
        :class="[$style['icon'], $style.weather, weather]"
        class="iconfont"
      ></span>
      <span :class="$style['text']">{{ weatherData.weather }}</span>
    </div>
    <div :class="$style.iconBox">
      <span :class="[$style['icon'], 'iconwendu']" class="iconfont"></span>
      <span :class="$style['text']"> {{ weatherData.temperature }}℃ </span>
    </div>
    <div :class="$style.iconBox">
      <span :class="[$style['icon'], 'iconbaofeng']" class="iconfont"></span>
      <span :class="$style['text']"> {{ weatherData.windpower }} </span>
    </div>
    <div :class="$style.iconBox">
      <span :class="[$style['icon'], $style['device']]"
        >{{ deviceInfo.onlineCount }}/{{ deviceInfo.totalCount }}</span
      >
      <span :class="$style['text']">{{ $t('screen.online') }}</span>
    </div>
    <!-- <router-link to="/main/generalView" v-if="noVisitorMode">
      <span class="TC tc-icon-zhuye3" :class="$style['home']"></span>
    </router-link> -->
    <template v-if="$p.action('IOT-SCREEN-GENERATE-SHARE-URL', '/iot/screen')">
      <div :class="$style.iconBox" v-if="noVisitorMode">
        <span
          @click="$emit('share')"
          :class="[$style['icon'], $style['share']]"
          class="TC tc-icon-fenxiang2"
        ></span>
      </div>
    </template>
    <fullScreenBtn :class="$style['full-screen']" />
  </div>
</template>

<script>
import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import { ssoClient } from '@triascloud/sso-client';
import { weatchObj } from '@/assets/js/weather.js';
import fullScreenBtn from '@/components/fullscreen-btn';
import createMessageBox from '@/views/iot/screen/components/message/create-message-box.js';
import {
  addOrDeleteAttention,
  addOrDeleteRecommend,
} from '@/services/things/attention-project';

@Component({
  components: { fullScreenBtn },
})
export default class ScreenIconInfo extends Vue {
  @Prop({ type: Object, default: {} }) weatherData;
  @Prop({ type: Object, default: {} }) deviceInfo;
  @Prop({ type: Object, default: {} }) attentionData;
  @Prop({ type: Object, default: {} }) recommendData;
  @Inject('noVisitorMode') noVisitorMode;

  get hasLogin() {
    try {
      if (ssoClient.getTokenSync()) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  isFullScreen = false;
  isMessage = false;
  isConcerned = false;
  // 获取未读消息数和预警和事件信息
  get unReadMessageCount() {
    const count =
      this.$store.getters['screen/faultCount'] +
      this.$store.getters['screen/warnCount'] +
      this.$store.getters['screen/infosCount'];

    return `${count}`;
  }
  get weather() {
    return weatchObj.getWeatch(this.weatherData.weather);
  }
  async openMessage() {
    this.noVisitorMode && this.$store.dispatch('screen/haveReadAll');
    this.isMessage = !this.isMessage;
    if (this.isMessage) {
      const result = await createMessageBox({
        style: {
          right: '4.5rem',
        },
      });
      result && (this.isMessage = false);
    }
  }
  get visitorInfo() {
    return this.$store.state.generalScreen.visitorInfo;
  }
  async checkConcerned() {
    const id = this.$route.params.projectId || this.visitorInfo.projectId;
    const paramsId = {
      projectId: id,
    };
    try {
      const data = await addOrDeleteAttention(paramsId);
      if (!this.attentionData.isAttention && data === 'CREATE') {
        this.attentionData.isAttention = true;
        this.$message.success(this.$t('screen.followSuccess'));
      } else if (this.attentionData.isAttention && data === 'DELETE') {
        this.attentionData.isAttention = false;
        this.$message.success(this.$t('screen.cancelFollow'));
      } else {
        this.attentionData.isAttention = false;
        this.$message.error(this.$t('screen.operationFailed'));
      }
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  async checkRecommend() {
    const id = this.$route.params.projectId || this.visitorInfo.projectId;
    const paramsId = {
      projectId: id,
      recommend: !this.recommendData.isRecommend,
    };
    try {
      await addOrDeleteRecommend(paramsId);
      if (!this.recommendData.isRecommend) {
        this.recommendData.isRecommend = true;
        this.$message.success(this.$t('screen.recommendedSuccessfully'));
      } else if (this.recommendData.isRecommend) {
        this.recommendData.isRecommend = false;
        this.$message.success(this.$t('screen.cancelRecommendation'));
      } else {
        this.recommendData.isRecommend = false;
        this.$message.error(this.$t('screen.operationFailed'));
      }
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  toThreePage() {
    // const projectId = localStorage.getItem('IOT_SCREEN_PROJECT_ID');
    const id = this.$route.params.projectId || this.visitorInfo.projectId;
    this.$router.push({
      path: `/iot/bm/${id}`,
    });
  }
}
</script>
<style lang="less" module>
.info-wrap {
  display: flex;
  align-items: center;
  color: #05d3ff;
  .message {
    position: relative;
    font-size: 0.23rem;
    .num {
      position: absolute;
      // background: #d41111;
      background-color: #f22020;
      color: #ffffff;
      display: block;
      left: 0.15rem;
      top: -0.15rem;
      border-radius: 0.125rem;
      font-size: 0.12rem;
      height: 0.225rem;
      line-height: 0.225rem;
      padding: 0 0.075rem;
    }
  }
  > * {
    margin-left: 0.4rem;
  }
  .weather {
    font-weight: 600;
  }
  .mouseCursor {
    cursor: pointer;
  }
  .iconBox {
    text-align: center;
    .icon {
      font-size: 0.18rem;
      display: block;
    }
    .device {
      font-size: 0.14rem;
    }
    .text {
      font-size: 0.14rem;
      display: block;
      padding-top: 0.05rem;
    }
    .share {
      font-size: 0.24rem;
      cursor: pointer;
      color: #05d3ff;
    }
  }

  .full-screen {
    width: 0.2rem;
    cursor: pointer;
  }
}
.un_read {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 0.1rem;
    height: 0.1rem;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #f22020;
  }
}
</style>
