<template>
  <info-tab :title="title">
    <div slot="right_0">
      <div :class="$style['rect_wrap']">
        <div :class="$style['rect_item']">
          <div :class="$style['rect']"></div>
          <div :class="$style['text']">&gt;10{{ $t('screen.days') }}</div>
        </div>
        <div :class="$style['rect_item']">
          <div :class="[$style['rect'], $style['rect-blue']]"></div>
          <div :class="$style['text']">&lt;=10{{ $t('screen.days') }}</div>
        </div>
      </div>
    </div>
    <div slot="content_0" :class="$style.container">
      <no-data-chart v-if="isNoData" />
      <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
    </div>
  </info-tab>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
import InfoTab from '../../info-tab';
import { dateFormatForMonthAndDay } from '@/utils';

const dataZoom = [
  {
    type: 'slider',
    show: true,
    start: 0,
    end: 100,
    xAxisIndex: [0],
    // handleSize: 20,//滑动条的 左右2个滑动条的大小
    height: 8, // 组件高度
    left: 40, // 左边的距离
    right: 10, // 右边的距离
    bottom: 0, // 右边的距离
    handleColor: 'transparen',
    fillerColor: '#075D82',
    borderColor: '#05314E',
    backgroundColor: '#05314E',
    handleStyle: {
      borderColor: '#cacaca',
      borderWidth: '0',
      shadowBlur: 0,
      backgroundColor: '#f8f8f8',
    },
    showDataShadow: false, // 是否显示数据阴影 默认auto
    showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
    handleIcon:
      'M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
    filterMode: 'filter',
  },
  // 下面这个属性是里面拖到
  {
    type: 'inside',
    show: true,
    xAxisIndex: [0],
    start: 1,
    end: 10,
    zoomOnMouseWheel: false,
  },
];

const forecastTime = (params, $t) => {
  if (params.predictionDate && params.nowDate !== params.predictionDate) {
    const dateFormat = dateFormatForMonthAndDay({
      longDate: params.predictionDate,
      currentLongDate: params.nowDate,
    });
    return `<span>${$t('screen.predictLift')}: ${dateFormat}</span>`;
  }
  return '';
};
const lineOptions = (data, nowSize, $t) => {
  const category = data.map(e => e.name);
  const values = data.map(e => e.value);
  let max = Math.max(...values);
  max += 10 - (max % 10);
  if (data.length > 8) {
    dataZoom[0].show = true;
    dataZoom[0]['end'] = 100 * (8 / data.length);
  } else {
    dataZoom[0].show = false;
  }
  return {
    backgroundColor: '',
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },

    tooltip: {
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },

      formatter: function(params) {
        let result = `${params.name}<br/>`;
        result += `<span>${$t('screen.latestLift', {
          val: params.value,
        })}</span><br/>`;

        // result += `<span>上次提升: ${params.value}天前</span><br/>`;
        result += forecastTime(params.data, $t);
        return result;
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
    },

    xAxis: {
      data: category,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        interval: 0,
        formatter: function(params) {
          if (params.length > 8) {
            return params.substring(0, 8) + '...';
          } else {
            return params;
          }
        },
      },
    },
    yAxis: {
      min: 0,
      max, // 设置y轴坐标最大值
      interval: max / 5, // 间隔
      splitLine: {
        show: true,
        lineStyle: {
          show: true,
          color: ['#1c1e32'],
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        formatter: `{a|{value}${$t('screen.days')}}`,
        width: 20,
        rich: {
          a: {
            align: 'left',
          },
        },
      },
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: true,
          color: '#b8b8bb',
        },
      },

      scale: true,
      splitNumber: 4,
      splitArea: { show: false },
    },
    series: [
      {
        name: '故障数',
        type: 'bar',
        barWidth: nowSize(18),
        barGap: '30%',
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: '{c}' + $t('screen.days'),
            color: '#fff',
            textBorderColor: 'transparent',
          },
        },

        itemStyle: {
          normal: {
            // 每根柱子颜色设置
            color: function(params) {
              var index_color = params.value;

              if (index_color > 10) {
                return '#EC7E35';
              } else {
                return '#06CAF7';
              }
            },
            label: {
              textStyle: {
                fontSize: nowSize(12),
              },
            },
          },
        },
        data: data,
      },
    ],
    dataZoom,
  };
};

@Component({
  mixins: [resize],
  components: {
    InfoTab,
  },
})
export default class PrevHighBuilding extends Vue {
  @Prop({ type: String, default: 'PrevHighBuilding' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  get title() {
    return [this.$t('screen.lastTimeLifted')];
  }
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  chart = null;
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$size, this.$t);
    this.chart.setOption(options);
  }
  resizeChart() {
    this.chart && this.chart.resize();
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
.rect_wrap {
  display: flex;
  .rect_item {
    font-size: 0.14rem;
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
  }
  .rect {
    width: 0.15rem;
    height: 0.1rem;
    margin-right: 0.1rem;
    background: #ec7e35;
  }
  .rect-blue {
    background: #07d3fd;
  }
}
</style>
