<template>
  <info-tab :title="title">
    <div slot="content_0" :class="$style.container">
      <no-data-chart v-if="isNoData" />
      <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
    </div>
  </info-tab>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import resize from '@/utils/resize';
import InfoTab from '../../info-tab';
const lineOptions = (data, nowSize) => {
  const category = data.map(e => dayjs(e.timeStamp).format('MM-DD'));
  const values = data.map(e => e.safeIndex);
  const min = Math.min(...values);
  const dataZoom = [
    {
      type: 'inside',
      startValue: category.length - 29,
      endValue: category.length,
      zoomLock: true,
    },
  ];
  return {
    dataZoom,
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      axisPointer: {
        type: 'line',
        z: 1,
        lineStyle: {
          color: '#fff',
          opacity: 0.2,
        },
      },
      formatter: function(params) {
        return `<span>${params[0].name}： ${params[0].value}%</span>`;
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
    },
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },

    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: category,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
          fontSize: 10,
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
      },
      triggerEvent: true,
    },
    yAxis: {
      min: min,
      max: '100', // 设置y轴坐标最大值
      // interval: 25, // 间隔
      splitLine: {
        show: false,
      },

      type: 'value',
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
          fontSize: 10,
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        formatter: '{a|{value}%}',
        width: 20,
        rich: {
          a: {
            align: 'left',
          },
        },
      },
    },

    series: [
      {
        name: 'test',
        type: 'line',
        stack: '总量',
        smooth: true,
        symbol: 'circle',
        symbolSize: 8,
        hoverAnimation: true,
        data: values,
        itemStyle: {
          normal: {
            // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: data.length > 1 ? 'rgba(0,0,0,0)' : '#05D3FF', // 背景渐变色
            lineStyle: {
              // 系列级个性化折线样式
              width: 3,
              type: 'solid',
              color: '#4fd6d2',
            },
          },
          emphasis: {
            color: '#05D3FF',
            borderWidth: 10,
            borderColor: 'rgba(5, 211, 255,0.5)',
            lineStyle: {
              // 系列级个性化折线样式
              width: 2,
              type: 'dotted',
              color: '#1dc2f1', // 折线的颜色
            },
          },
        }, // 线条样式
        areaStyle: {
          normal: {
            // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(80,141,255,0.39)',
              },
              {
                offset: 0.34,
                color: 'rgba(56,155,255,0.25)',
              },
              {
                offset: 1,
                color: 'rgba(38,197,254,0.00)',
              },
            ]),
          },
        }, // 区域颜
      },
      {
        name: 'tes',
        type: 'bar',
        barWidth: 22,
        itemStyle: {
          color: 'transparent',
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        data: values.map(() => 100),
        animation: false,
      },
    ],
  };
};
@Component({
  mixins: [resize],
  components: {
    InfoTab,
  },
})
export default class SafeBuild extends Vue {
  @Prop({ type: String, default: 'SafeBuild' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: () => [] }) list;
  get title() {
    return [this.$t('screen.safeRate')];
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    return this.hasInitError || !this.list || this.list.length === 0;
  }
  chart = null;
  resizeChart() {
    this.chart && this.chart.resize();
  }
  hasInitError = false;
  setChartOptions() {
    try {
      if (!this.chart) {
        this.chart = echarts.init(document.getElementById(this.id));
        this.chart.on('click', e => {
          const index =
            e.componentType === 'series'
              ? e.dataIndex
              : this.chart.getOption().xAxis[0].data.indexOf(e.value);
          this.$emit('showSafeModal', index);
        });
      }
      const options = lineOptions(this.list, this.$size);
      this.chart.setOption(options);
    } catch (error) {
      this.hasInitError = true;
    }
  }
  @Watch('list')
  onWatchList(value) {
    if (value && value.length > 0) {
      this.$nextTick(this.setChartOptions);
    }
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
